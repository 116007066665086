<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none">
          <div class="card-body p-5">
            <div class="row mb-4">
              <div class="col-auto text-center">
                <img
                  src="@/assets/images/people/sue_camp.jpg"
                  class="rounded-circle mb-4 mx-auto"
                  style="height: 150px"
                />
                <h5 class="text-primary fw-bold">Sue Camp</h5>
                <p class="text-primary fw-bold">
                  <small>Head of Practitioner Development</small>
                </p>
              </div>
              <div class="col">
                <p class="mb-4">
                  Welcome to Swandoola, this is the
                  <strong>Practitioner Portal</strong>, from here you can manage
                  your clinic, clients, services, video consultations, events,
                  tasks and much more.
                </p>
                <p class="mb-5">
                  If you are interested in joining
                  <strong>Swandoola</strong> you can do so by clicking here, or
                  emailing
                  <a href="mailto:hello@swandoola.com">hello@swandoola.com</a>
                  to arrange a demo with a member of the Swandoola team.
                </p>
                <div class="row">
                  <div class="col-auto ms-auto">
                    <router-link to="/login" class="btn btn-primary btn-lg">
                      <i class="far fa-sign-in me-2"></i>Practitioner Login
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <p class="mb-0">
                  <small
                    ><i
                      >The Swandoola Practitioner Portal is designed to be used
                      from a desktop or laptop computer, your experience may
                      vary on smaller devices.</i
                    ></small
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>